// Generated by Framer (401b244)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["kHL60LyEb"];

const serializationHash = "framer-1Donk"

const variantClassNames = {kHL60LyEb: "framer-v-onfsq2"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "kHL60LyEb", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-onfsq2", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"kHL60LyEb"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-1re3oj6"} data-framer-name={"Airtable"} fill={"black"} intrinsicHeight={48} intrinsicWidth={48} layoutDependency={layoutDependency} layoutId={"QT4yCsWbp"} svg={"<svg width=\"48\" height=\"48\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><g clip-path=\"url(#a)\"><path d=\"M21.434 4.507 3.538 11.912c-.995.412-.985 1.826.017 2.222l17.97 7.127a6.668 6.668 0 0 0 4.916 0l17.97-7.127c1.002-.396 1.013-1.81.017-2.222L26.533 4.507a6.668 6.668 0 0 0-5.1 0\" fill=\"#FCB400\"/><path d=\"M25.578 25.152v17.803a1.2 1.2 0 0 0 1.64 1.114l20.025-7.772A1.198 1.198 0 0 0 48 35.182V17.38c0-.847-.854-1.427-1.64-1.115l-20.026 7.773a1.2 1.2 0 0 0-.756 1.114Z\" fill=\"#18BFFF\"/><path d=\"m20.901 26.07-5.942 2.87-.604.292-12.545 6.01c-.795.384-1.81-.195-1.81-1.079V17.454c0-.32.164-.595.384-.803.09-.09.192-.166.304-.228.299-.18.727-.228 1.09-.084l19.024 7.538c.967.383 1.043 1.738.1 2.194Z\" fill=\"#F82B60\"/><path d=\"m20.901 26.07-5.942 2.87L.384 16.65c.09-.089.192-.165.304-.227.299-.18.727-.228 1.09-.084l19.024 7.538c.967.383 1.043 1.738.1 2.194Z\" fill=\"#000\" fill-opacity=\".25\"/></g><defs><clipPath id=\"a\"><path fill=\"#fff\" d=\"M0 0h48v48H0z\"/></clipPath></defs></svg>"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-1Donk.framer-302p1w, .framer-1Donk .framer-302p1w { display: block; }", ".framer-1Donk.framer-onfsq2 { height: 48px; overflow: visible; position: relative; width: 48px; }", ".framer-1Donk .framer-1re3oj6 { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 48px); left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 48
 * @framerIntrinsicWidth 48
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerlcHaWTljx: React.ComponentType<Props> = withCSS(Component, css, "framer-1Donk") as typeof Component;
export default FramerlcHaWTljx;

FramerlcHaWTljx.displayName = "Airtable";

FramerlcHaWTljx.defaultProps = {height: 48, width: 48};

addFonts(FramerlcHaWTljx, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})